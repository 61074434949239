<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('lab_report') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          class="d-none d-md-block"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            :label="`${$t('search')} (${$t('pressEnterForSearch')})`"
            @keypress.enter="addPayload(payload.start, payload.end)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-header
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:header>
          <tr
            height="43px"
          >
            <th
              rowspan="2"
              class="text-left pl-4"
              style="min-width: 80px"
            >
              #
            </th>
            <th
              rowspan="2"
              class="text-left pl-4"
              style="min-width: 220px"
            >
              {{ $t('firstname_lastname') }}
            </th>
            <th
              rowspan="2"
              class="text-left pl-4"
              style="min-width: 180px"
            >
              {{ $t('a_number') }}
            </th>
            <th
              rowspan="2"
              class="text-left pl-4"
              style="min-width: 280px"
            >
              {{ $t('examination_list') }}
            </th>
            <th
              colspan="3"
              align="center"
            >
              {{ $t('test_result') }}
            </th>
            <th
              colspan="3"
              align="center"
            >
              {{ $t('previous_results') }}
            </th>
          </tr>
          <tr height="43px">
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('examination_date') }}
            </th>
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('lab_results') }}
            </th>
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('reference_value') }}
            </th>
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('examination_date') }}
            </th>
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('lab_results') }}
            </th>
            <th
              style="min-width: 180px"
              class="text-left pl-4"
            >
              {{ $t('reference_value') }}
            </th>
          </tr>
        </template>
        <template v-slot:[`item.opd_code`]="{ item }">
          <router-link
            v-if="item.opd_id"
            :to="{ name: 'ShowDetailCheck', params: { id: item.opd_id } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.opd_code }}
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportLab } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate } from '@/plugins/filters'
import shop from '@/api/shop'

export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const XLSX = require('xlsx')
    const payload = ref({})
    const searchtext = ref('')
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const shop_name = ref('')
    const shop_address = ref('')
    const shop_tel = ref('')
    const columns = ref([
      {
        text: '#', value: 'number',
      },
      { text: i18n.t('firstname_lastname'), value: 'customer_fullname' },
      { text: i18n.t('a_number'), value: 'opd_code' },
      { text: i18n.t('examination_list'), value: 'opdchecklist_name' },
      { text: i18n.t('examination_date'), value: 'opd_date' },
      { text: i18n.t('lab_results'), value: 'opdchecklist_value' },
      { text: i18n.t('reference_value'), value: 'opdchecklist_ref' },
      { text: i18n.t('examination_date'), value: 'opd_date_old' },
      { text: i18n.t('lab_results'), value: 'opdchecklist_value_old' },
      { text: i18n.t('reference_value'), value: 'opdchecklist_ref_old' },
    ])

    shop.shop().then(response => {
      shop_name.value = `${response.shop_name} ${i18n.t(
        'license_number',
      )} ${response.shop_nature} `
      shop_address.value = ` ${i18n.t('number')} ${
        response.shop_address
      }  ${i18n.t('sub_district')} ${
        response.shop_district
      } ${i18n.t('district')} ${response.shop_amphoe}  ${i18n.t(
        'province',
      )}  ${response.shop_province} ${i18n.t('a_zipcode')} ${
        response.shop_zipcode
      } `
      shop_tel.value = `${i18n.t('tel')} ${
        response.shop_tel
      } ${i18n.t('email')}  ${response.shop_email} ${i18n.t(
        'tax_id',
      )} ${response.shop_tax} `
    })

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportLab(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('labReport')} ${i18n.t(
        'since',
      )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}.xlsx`

      //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${shop_name.value} `],
        [`${shop_address.value} `],
        [`${shop_tel.value} `],
        [
          '',
          '',
          '',
          '',
          `${i18n.t('test_result')}`,
          '',
          '',
          `${i18n.t('previous_results')}`,
          '',
          '',
          '',
        ],
        [
          '#',
          `${i18n.t('firstname_lastname')}`,
          `${i18n.t('a_number')}`,
          `${i18n.t('examination_list')}`,
          `${i18n.t('examination_date')}`,
          `${i18n.t('lab_results')}`,
          `${i18n.t('reference_value')}`,
          `${i18n.t('examination_date')}`,
          `${i18n.t('lab_results')}`,
          `${i18n.t('reference_value')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataTableList.value, {
        header: [
          'number',
          'customer_fullname',
          'opd_code',
          'opdchecklist_name',
          'opd_date',
          'opdchecklist_value',
          'opdchecklist_ref',
          'opd_date_old',
          'opdchecklist_value_old',
          'opdchecklist_ref_old',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
        { s: { r: 3, c: 4 }, e: { r: 3, c: 6 } },
        { s: { r: 3, c: 7 }, e: { r: 3, c: 9 } },
      ]
      const wscols = [
        { wch: 8 },
        { wch: 25 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('labReport')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      payload,
      searchtext,
      loading,
      exportLoading,
      options,
      dataTableList,
      columns,
      addPayload,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>
